import React from 'react';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';
import _ from 'lodash';
import axios from 'axios';

const SortableItem = SortableElement(({customer, position, deliverFoodFn}) => {
  return (<li className='road-trip'>
    <div className="basic-info">
      <strong>{customer.full_name}</strong> ({_cateringsQuantity(customer)})
      <br/>
      {customer.address}, {customer.city}
      <br/>
      {customer.driver_comment}
    </div>
    <div className="actions">
      <a className="address btn btn-default" target="_roadtrip" href={_googleUrl(customer)}>
        <i className="fa fa-2x fa-map-marker-alt"/>
      </a>

      <a href={"tel:+" + customer.phone} className="btn btn-warning"><i className="fa fa-phone fa-2x"/></a>

      <a href={`sms:+${customer.phone}?body=${encodeURIComponent('Dzien dobry, catering zostal dostarczony, zyczymy smacznego <3')}`} className="btn btn-primary"><i className="fa fa-envelope fa-2x"/></a>

      <a className="devliverd btn btn-success" onClick={(e) => deliverFoodFn(e, customer, position)}>
        <i className="fa fa-check-circle fa-2x"/>
      </a>
    </div>
  </li>)
});

const SortableList = SortableContainer(({items, deliverFoodFn}) => {
  return (
    <ul className="road-trip">
      {items.map((customer, index) => {
        return <SortableItem key={`item-${index}`} position={index} index={index} customer={customer} deliverFoodFn={deliverFoodFn} />
      })}
    </ul>
  );
});

const SortableHnadle = SortableHandle(() => {
  return <span className='sortable-hnadle btn btn-danger'><i className="fa fa-arrows-alt-v fa-2x"/></span>
});

function _setDelivered(e, customer) {
  e.preventDefault();

  axios.post(this.props.delivered_path, {
    quantity: _cateringsQuantity(customer),
    customer_id: customer.id
  }, {
    headers: {'X-CSRF-Token': this.props.csrf_token},
  })
    .then((response) => {
      // this.setState({foods: response.data})
      console.log(response);
    })
    .catch((error) => {
      console.log(error);
    });
};

function _toggleView(e) {
  console.log(e);
}

function _cateringsQuantity(customer) {
  let qty = 0;
  return qty + customer.quantity_1000 * 1 + customer.quantity_1200 * 1 + customer.quantity_1500 * 1 + customer.quantity_1750 * 1
    + customer.quantity_2000 * 1 + customer.quantity_2500 * 1 + customer.quantity_3000 * 1
}

function _googleUrl(customer) {
  const address = customer.address + ', ' + customer.city + ' ' + customer.postcode;
  return 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(address)
    + '&query_place_id=' + customer.id;
};

export default class RoadTrip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: this._handleCustomers(),
      sort: 'asc'
    };
  }

  _handleCustomers() {
    let sorted_customers = [];
    _.mapValues(this.props.records, (customer) => {
      sorted_customers.push(customer)
    });
    return sorted_customers;
  }

  onSortEnd({oldIndex, newIndex}) {
    this.setState(({items}) => ({
      items: arrayMoveImmutable(items, oldIndex, newIndex),
    }));
    axios.put(this.props.customers_rank_url, {
      customers: JSON.stringify(this.state.items)
    }, {
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })
  };

  _toggleSorting() {
    if (this.state.sort == 'asc') {
      this.setState({
        items: this.state.items.reverse(),
        sort: 'desc'
      });
    } else {
      this.setState({
        items: this.state.items.reverse(),
        sort: 'asc'
      });
    }
  }

  _deliverFood(e, customer, position) {
    e.preventDefault();
    // console.log('*****', this.state.items, index);
    let customers = this.state.items;
    customers.splice(position, 1);
    const amount = prompt('Podaj kwotę, jaką zapłacił klient (tylko liczba: 100):', 0);
    if (amount != null) {
      this.setState({
        items: customers
      });
      this._handleRequest(customer, Number(amount))
    }
  }

  _handleRequest(customer, amount) {
    axios.post(this.props.delivered_path, {
      amount: amount,
      quantity: _cateringsQuantity(customer),
      customer_id: customer.id
    }, {
      headers: {'X-CSRF-Token': this.props.csrf_token, 'Content-Type': 'application/json'},

    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
    // this.props.removeCustomer(this.props.customer.id);
  }

  render() {
    const totalBags = [];
    this.state.items.map((customer) => {
      totalBags.push(_cateringsQuantity(customer))
    });
    return <div>
      <br/>
      <a onClick={() => this._toggleSorting()} className="btn btn-primary">odwróć kolejność</a>
      &nbsp;
      &nbsp;
      <a href={`/road_planner/${this.props.route}`} className="btn btn-warning">ustaw trase</a>
      <br/>
      <br/>
      naklejek na torbach: <strong>{totalBags.reduce((a, b) => a + b, 0)} </strong>
      <br/>
      <SortableList items={this.state.items} lockAxis='y' useDragHandle={true} helperClass='road-trip'
                    onSortEnd={this.onSortEnd.bind(this)} deliverFoodFn={this._deliverFood.bind(this)}/>
    </div>
  }
}
